@font-face {
  font-family: "EBGaramond-Bold";   /*Can be any text*/
  src: local("EBGaramond-Bold"),
    url("../public/fonts/EBGaramond-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "EBGaramond-Italic";   /*Can be any text*/
  src: local("EBGaramond-Italic"),
    url("../public/fonts/EBGaramond-Italic.ttf") format("truetype");
}
